import * as navigationActions from './navigationActions.js';
import * as navigationGetters from './navigationGetters.js';
import * as navigationMutations from './navigationMutations.js';

export const NAVIGATION = {
    namespaced: true,
    state: () => ({
        items: false,
        lastUpdated: null,
        token: null,
        crsf: null,
        event: 'NAVIGATION_UPDATING',
        branding: {
            lockup: null,
        }
    }),
    actions: navigationActions,
    getters: navigationGetters,
    mutations: navigationMutations,
}