import * as formActions from './formActions.js';
import * as formGetters from './formGetters.js';
import * as formMutations from './formMutations.js';

export const FORM = {
    namespaced: true,
    state: () => ({
        fields: null,
        form: null,
        token: null,
        csrf: null,
        errors: null,
        success: false
    }),
    actions: formActions,
    getters: formGetters,
    mutations: formMutations
}