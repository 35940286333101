import App from '@/vue/App.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import hcMore from 'highcharts/highcharts-more'
import VueClickAway from "vue3-click-away"
import { store } from './stores/store.js'
import './assets/icons.js'

// Import our CSS
import '@/css/app.pcss';

// App main
const main = async () => {

    const pinia = createPinia()
    const app = createApp(App)

    hcMore(Highcharts)

    // Mount third parties
    app.use(pinia)
    app.use(VueClickAway)
    app.use(HighchartsVue as any)
    app.use(store)

    // Mount the app 
    const root = app.mount('#page-container')

    return root
}

// Execute async function
main().then(() => {
    console.log()
})