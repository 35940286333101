export const GET_FORM = (handle) => {
    let query = `
        {
            formieForm(handle: "${handle}") {
                title
                configJson
                rows {
                    rowFields {
                        id
                        errorMessage
                        instructions
                        instructionsPosition
                        name
                        typeName
                        required
                        displayName
                        visibility
                        handle
                        enableConditions
                        conditions
                        ... on Field_Checkboxes {
                            id
                            name
                            typeName    
                            options {
                                label
                                value
                                isDefault
                            }
                        }
                        ... on Field_Agree {
                            id
                            name
                            typeName    
                            required
                            checkedValue
                            uncheckedValue
                        }
                        ... on Field_Dropdown {
                            id
                            name
                            typeName
                            defaultValue
                            options {
                                label
                                value
                                isDefault
                            }
                            placeholder
                        }
                        ... on Field_Radio {
                            id
                            name
                            typeName    
                            defaultValue
                            options {
                                value
                                label
                                isDefault
                            }
                        }
                        ... on Field_Hidden {
                            defaultValue
                            typeName    
                            defaultOption
                            queryParameter
                        }
                        ... on Field_Heading {
                            name
                            typeName    
                            headingSize
                        }
                        ... on Field_Number {
                            limit
                            max
                            min
                        }
                    }
                }
                settings {
                    redirectEntry {
                        url
                    }
                    redirectUrl
                    submitAction
                    submitActionMessageHtml
                }
            }
        }
    `;

    return query;
}

export const SUBMIT_FORM = (variables) => {

    const params = variables.fields.map(field => {

        if (field.typeName === 'Field_Checkboxes') {
            return '$' + field.handle + ': [String]'
        }

        if (field.typeName === 'Field_Number') {
            return '$' + field.handle + ': Number'
        }

        return '$' + field.handle + ': String'
    })

    const vars = variables.fields.map(field => {
        return field.handle + ': $' + field.handle
    })

    return `
        mutation SaveForm(${params.join(', ')}) {
            save_${variables.form}_Submission(
                ${vars.join(', ')}
            )
            {
                title
            }
        }
    `
}
