const domain = import.meta.env.VITE_SITE_URL
const environemnt = import.meta.env.VITE_ENVIRONMENT

export const ENV = (): string => environemnt

export const ENDPOINT = (): string => (domain ?? 'https://evidenceforlearning.org.au') + '/'

// export const ENV = (): string => {

//     if(window) {

//         const URL = window.location.href

//         if ( URL.match(/(192.168|localhost)/) ) {
//             return 'dev'
//         } else if ( URL.match(/(e4l.educationendowmentfoundation)/) ) {
//             return 'staging'
//         } else {
//             return 'production'
//         }

//     }

//     return 'dev'

// }

export const INDICES = (indices: string[], env: string): (string | undefined)[] => {

    const constructIndices = indices.map(entry => {
        return `e4l_${env}_${entry}`
    })

    return constructIndices

}
