export const GET_CSRF = (state) => {
    return state.csrf
}

export const GET_NAV_TOKEN = (state) => {
    return state.token
}

export const NAV_ITEMS = (state) => {

    return state.items ? state.items : false

}

export const GET_BRANDING = (state) => {

    return state.branding ? state.branding : false

}

export const GET_EVENT = (state) => {

    return state.event ? state.event : false

}