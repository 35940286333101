import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { ENV } from '@/js/utils/environment'

import * as actions from './actions.js'
import * as getters from './getters.js'
import * as mutations from './mutations.js'

/* ----------- NAVIGATION ----------- */
import { NAVIGATION } from './navigation/navigationStore'

const navigationState = createPersistedState({
    paths: ['navigation.csrf', 'navigation.items', 'navigation.lastUpdated', 'navigation.token', 'navigation.event', 'navigation.branding'],
    key: 'e4l-nav'
})
/* ----------- FORM ----------- */
import { FORM } from './form/formStore.js'

/* ----------- UI ----------- */
import { UI } from './ui/uiStore.js'

const uiState = createPersistedState({
    paths: ['ui.showSubscription'],
    key: 'e4l-ui'
})


// Main Store
export const store = createStore({
    namespaced: true,
    state: () => ({
        environment: ENV(),
    }),
    actions,
    getters,
    mutations,
    modules: {
        navigation: NAVIGATION,
        ui: UI,
        form: FORM,
    },
    plugins: [navigationState, uiState]
});
