export const SET_CSRF = (state, data) => {
    state.csrf = data;
}

export const SET_TOKEN = (state, data) => {
    state.token = data;
}

export const SET_SEARCH_SETTINGS = (state, data) => {
    state.searchSettings = data;
}

export const SHOW_SUBSCRIPTION = (state, data) => {
    state.showSubscription = data;
}

export const SET_PLACEHOLDERS = (state, data) => {
    state.placeholders = data;
    state.loading = false;
}
