<script>

import { defineAsyncComponent, defineComponent } from 'vue'
import SkeletonNavigation from '@/vue/organisms/navigations/navigation--skeleton.vue'

export default defineComponent({

    components: {

        /** ATOMS */
        'background--graphic': defineAsyncComponent(() => import(/* webpackChunkName: "background" */ '@/vue/atoms/backgrounds/background--graphic.vue')),
        'button--group': defineAsyncComponent(() => import(/* webpackChunkName: "button--group" */ '@/vue/atoms/buttons/button--group.vue')),
        'button--scroll-indicator': defineAsyncComponent(() => import(/* webpackChunkName: "button--group" */ '@/vue/atoms/buttons/button--scroll-indicator.vue')),
        'progress--primary': defineAsyncComponent(() => import(/* webpackChunkName: "project" */ '@/vue/atoms/progress/progress--primary.vue')),

        /** MOLECULES */
        'bar--subscribe': defineAsyncComponent(() => import(/* webpackChunkName: "bar--subscribe" */ '@/vue/molecules/bars/bar--subscribe.vue')),
        'card--guidance-teachers': defineAsyncComponent(() => import(/* webpackChunkName: "guidance" */ '@/vue/molecules/cards/card--guidance-teachers.vue')),
        'card--guidance-report-downloads': defineAsyncComponent(() => import('@/vue/molecules/cards/card--guidance-report-downloads.vue')),
        'card--project': defineAsyncComponent(() => import(/* webpackChunkName: "project" */ '@/vue/molecules/cards/card--project.vue')),
        'card--toolkit': defineAsyncComponent(() => import(/* webpackChunkName: "toolkit" */ '@/vue/molecules/cards/card--toolkit.vue')),
        'card--toolkit-mini': defineAsyncComponent(() => import(/* webpackChunkName: "toolkit" */ '@/vue/molecules/cards/card--toolkit-mini.vue')),
        'filter--multiselect': defineAsyncComponent(() => import(/* webpackChunkName: "filter--multiselect" */ '@/vue/molecules/filters/filter--multiselect.vue')),
        'notification--cookie': defineAsyncComponent(() => import(/* webpackChunkName: "notification--cookie" */ '@/vue/molecules/notifications/notification--cookie.vue')),
        'listitem--faq': defineAsyncComponent(() => import(/* webpackChunkName: "listitem" */ '@/vue/molecules/listitems/listitem--faq.vue')),
        'listitem--resource-paywall': defineAsyncComponent(() => import(/* webpackChunkName: "listitem" */ '@/vue/molecules/listitems/listitem--resource-paywall.vue')),

        /** ORGANISMS */
        'form--primary': defineAsyncComponent(() => import(/* webpackChunkName: "form--primary" */ '@/vue/organisms/forms/form--primary.vue')),
        'grid--news': defineAsyncComponent(() => import(/* webpackChunkName: "news" */ '@/vue/organisms/grids/grid--news.vue')),
        'grid--projects': defineAsyncComponent(() => import(/* webpackChunkName: "projects" */ '@/vue/organisms/grids/grid--projects.vue')),
        'navigation--content': defineAsyncComponent(() => import(/* webpackChunkName: "navigation" */ '@/vue/organisms/navigations/navigation--content.vue')),
        'navigation--main': defineAsyncComponent({
            loader: () => import(/* webpackChunkName: "navigation" */ '@/vue/organisms/navigations/navigation--main.vue'),
            loadingComponent: SkeletonNavigation,
        }),
        'navigation--news': defineAsyncComponent(() => import(/* webpackChunkName: "navigation" */ '@/vue/organisms/navigations/navigation--news.vue')),
        'navigation--project': defineAsyncComponent(() => import(/* webpackChunkName: "navigation" */ '@/vue/organisms/navigations/navigation--project.vue')),
        'navigation--technical-appendix': defineAsyncComponent(() => import(/* webpackChunkName: "navigation" */ '@/vue/organisms/navigations/navigation--technical-appendix.vue')),
        'popup--video': defineAsyncComponent(() => import(/* webpackChunkName: "popup" */ '@/vue/organisms/popups/popup--video.vue')),
        'search--main': defineAsyncComponent(() => import(/* webpackChunkName: "search--main" */ '@/vue/organisms/search/search--main.vue')),
        'view--error': defineAsyncComponent(() => import(/* webpackChunkName: "errors" */ '@/vue/organisms/views/view--error.vue')),
        'view--fiveothree': defineAsyncComponent(() => import(/* webpackChunkName: "errors" */ '@/vue/organisms/views/view--fiveothree.vue')),
        'view--fourofour': defineAsyncComponent(() => import(/* webpackChunkName: "errors" */ '@/vue/organisms/views/view--fourofour.vue')),
        'view--guidance-additional-downloads': defineAsyncComponent(() => import('@/vue/organisms/views/view--guidance-additional-downloads.vue')),
        // 'view--guidance-report': defineAsyncComponent(() => import('@/vue/organisms/views/view--guidance-report.vue')),
        'view--news': defineAsyncComponent(() => import(/* webpackChunkName: "news" */ '@/vue/organisms/views/view--news.vue')),
        'view--projects': defineAsyncComponent(() => import(/* webpackChunkName: "projects" */ '@/vue/organisms/views/view--projects.vue')),
        'view--toolkit': defineAsyncComponent(() => import(/* webpackChunkName: "toolkit" */ '@/vue/organisms/views/view--toolkit.vue')),
        'view--toolkit-ey': defineAsyncComponent(() => import(/* webpackChunkName: "toolkit-ey" */ '@/vue/organisms/views/view--toolkit-ey.vue')),
        'view--toolkit-appendix': defineAsyncComponent(() => import(/* webpackChunkName: "toolkit" */ '@/vue/organisms/views/view--toolkit-appendix.vue')),

    },

    async mounted(){

        if(!this.GET_TOKEN) {
            await this.$store.dispatch('ui/FETCH_CSRF')
            await this.$store.dispatch('ui/FETCH_TOKEN')
        }

        await this.$store.dispatch("ui/FETCH_PLACEHOLDERS")
    },

    methods: {

        printPage() {
            window.print()
        },

        smoothScroll(event, target) {
            this.$refs.contentNav.scrollTo(event, true, target)
        }

    }

})
</script>

