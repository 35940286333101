export const GET_CSRF = (state) => {
    return state.csrf
}

export const GET_TOKEN = (state) => {
    return state.token
}


export const GET_SEARCH_SETTINGS = (state) => {
    if ( state.searchSettings ) {
        return state.searchSettings
    }

    return null
}

export const GET_SHOW_SUBSCRIPTION = (state) => {
    if (state.showSubscription ) {
        return state.showSubscription
    }

    return false;
}

export const GET_PLACEHOLDERS = (state) => {
    return state.placeholders;
}

export const GET_LOADING = (state) => {
    return state.loading
}
