// Configure the GraphQL api endpoint
export const configureGqlApi = (url, token) => ({
    baseURL: url,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        ...(token && { 'Authorization': `Bearer ${token}` }),
    }
});

// Execute a GraphQL query by sending an XHR to our api endpoint
export const executeGqlQuery = async(api, query, variables, callback) => {

    // Execute the GQL query
    try {
        const response = await api.post('', {
            query: query,
            variables: variables
        });

        if ( callback && response.data.errors) {

            callback(response.data.errors)

        } else if ( callback && response.data.data ) {

            callback(response.data.data);

        }

        // Log any errors
        if (callback && response.data.errors) {
            callback(response.data.errors)
        }

    } catch (error) {

        if ( callback && error.response ) {
            callback(error.response)
        }

    }
};

export const executeGqlQueryWithErrors = async (api, query, variables, callback, error) => {

    // Execute the GQL query
    try {
        const response = await api.post('', {
            query: query,
            variables: variables
        });

        if (error && response.data.errors) {

            error(response.data.errors)

        }

        if (callback && response.data.data) {

            callback(response.data.data);

        }

        // Log any errors
        if (error && response.data.errors) {
            error(response.data.errors)
        }

    } catch (error) {

        if (error && error.response) {
            error(error.response)
        }

    }
};

