import axios from 'axios';
import { configureXhrApi, executeXhr } from '@/js/utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '@/js/utils/gql.js';
import { PLACEHOLDERS_QUERY } from '@/js/data/uiQueries.js';

/* TODO: CREATE NAVIGATION CONTROLLER FOR TOKEN */
const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';

// Fetch & commit the CSRF token
export const FETCH_CSRF = async ({ commit }) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));

    let variables = {};

    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_CSRF', data);
    });
};

// Fetch & commit the GraphQL token
export const FETCH_TOKEN = async ({ commit, state }) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_TOKEN', data);
    });
};

export const SEARCH_EXPANSION = async({commit, state}, payload) => {

    let menu = state.searchSettings

    menu.expanded = payload;

    if ( menu.expanded !== state.searchSettings.expanded ) {

        commit( 'SET_SEARCH_SETTINGS', menu )

    }

}

export const ERROR_PAGE = async ({ commit, state }, payload) => {

    let settings = state.searchSettings

    settings.errorPage = payload;

    if (settings.errorPage !== state.searchSettings.errorPage) {

        commit('SET_SEARCH_SETTINGS', settings)

    }

}

export const SHOW_SUBSCRIPTION = async({commit}, payload) => {
    commit('SHOW_SUBSCRIPTION', payload);
}

export const FETCH_PLACEHOLDERS = async ({ commit, state }) => {
    const token = state.token ? state.token.token : null;

    state.loading = true;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, PLACEHOLDERS_QUERY, variables, (data) => {
        if (data.globalSet) {
            commit('SET_PLACEHOLDERS', data.globalSet);
        }
    })
}
