import axios from 'axios';
import { configureXhrApi, executeXhr } from '@/js/utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '@/js/utils/gql.js';
import { NAVIGATION_ITEMS, NAVIGATION_LAST_UPDATED } from '@/js/data/navigationQueries.js';

/* TODO: CREATE NAVIGATION CONTROLLER FOR TOKEN */
const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';

// Fetch & commit the CSRF token
export const FETCH_CSRF = async({commit}) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));

    let variables = {};

    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_CSRF', data);
    });
};

// Fetch & commit the GraphQL token
export const FETCH_NAV_TOKEN = async({commit, state}) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_TOKEN', data);
    });
};

// Fetch & commit the navigation items
export const FETCH_ITEMS = async({commit, state}) => {

    const token = state.token ? state.token.token : null;
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    await executeGqlQuery(api, NAVIGATION_ITEMS, null, (payload) => {

        if ( payload && payload.entries ) {
            commit('SET_NAV_ITEMS', payload.entries)
        }

        if ( payload && payload.entry ) {           
            commit('SET_LAST_UPDATED', payload.entry.dateUpdated)
        }

        if ( payload && payload.globalSet ) {
            commit('SET_BRANDING', payload.globalSet.lockup[0].url)
        }

    })

    commit('SET_EVENT', 'NAVIGATION_UPDATED')

}

export const REFRESH_NAV = async({ dispatch }) => {

    await dispatch('FETCH_CSRF')
    await dispatch('FETCH_NAV_TOKEN')
    await dispatch('NAVIGATION_LAST_UPDATED_CHECK')

}

export const NAVIGATION_LAST_UPDATED_CHECK = async({commit, dispatch, state}) => {

    const token = state.token ? state.token.token : null;
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    await executeGqlQuery(api, NAVIGATION_LAST_UPDATED, null, (payload) => {

        if ( payload && payload.data?.error ) {

            switch ( payload.status ) {

            case 400: 
                dispatch('REFRESH_NAV')
                break

            }

        }

        if ( payload && payload.entry ) {   
            
            if ( !state.lastUpdated || (state.lastUpdated < payload.entry.dateUpdated) ) {

                commit('SET_EVENT', 'NAVIGATION_UPDATING')
                dispatch('FETCH_ITEMS')

            } else {

                state.items.forEach(item => {
                    if ( !Object.prototype.hasOwnProperty.call(item.target, 'element') ) {
        
                        commit('SET_EVENT', 'NAVIGATION_UPDATING')
                        dispatch('FETCH_ITEMS')
        
                    }
                })

                commit('SET_EVENT', 'NAVIGATION_UPDATED')

            }
            
        }

    })

}