import axios from 'axios';
import { configureXhrApi, executeXhr } from '@/js/utils/xhr.js';
import { configureGqlApi, executeGqlQuery, executeGqlQueryWithErrors } from '@/js/utils/gql.js';
import { GET_FORM, SUBMIT_FORM } from '@/js/data/formQueries.js';

const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';

// Fetch & commit the CSRF token
export const FETCH_CSRF = async({commit}) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));

    let variables = {};

    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_CSRF', data);
    });
};

// Fetch & commit the GraphQL token
export const FETCH_TOKEN = async({commit, state},) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('SET_TOKEN', data);
    });
};

export const FETCH_FORM = async({commit, state}, variables) => {
    const token = state.token ? state.token.token : null;
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    await executeGqlQuery(api, GET_FORM(variables), null, (payload) => {
        if(payload && payload.formieForm){

            let fields = []

            payload.formieForm.rows.forEach(row => {
                fields.push(...row.rowFields)
            })

            payload.formieForm.fields = fields

            commit('SET_FORM', payload.formieForm);
        }
    })
}

export const RESET_FORM = async({commit}) => {
    commit('SET_ERRORS', null);
    commit('SET_SUCCESS', false);
}

export const SAVE_FORM = async({commit, state}, variables) => {
    const token = state.token ? state.token.token : null;
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    await executeGqlQueryWithErrors(
        api,
        SUBMIT_FORM(variables),
        variables.values,
        (payload) => {
            if(payload){

                // redirect if set
                switch(state.form.settings.submitAction) {
                case 'url':
                    window.location.href = state.form.settings.redirectUrl
                    break
                case 'entry':
                    window.location.href = state.form.settings.redirectEntry.url
                    break
                }
                
                commit('SET_SUCCESS', true);
            }
        },
        (error) => {
            if(error){
                commit('SET_ERRORS', JSON.parse(error[0].message));
                commit('SET_SUCCESS', false);
            }
        }
    )
}