export const NEWS_QUERY = `
    query {
        entries(section: "news") {
            id,
            title,
            slug,
            ...on news_news_Entry{
                teaserImage {
                    ...on teasers_Asset{
                        optimizeTeasers{
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
            }
        }
    }
`