// TODO CREATE PARTIAL FOR ENTRY QUERY

export const NAVIGATION_ITEMS = `
    query {
        entries(section: "navigation") {
            id,
            title,
            level,
            children {
                ...on navigation_mainItem_Entry{
                    level,
                    target {
                        url,
                        text
                        element {
                            status
                        }
                    },
                    children {
                        ...on navigation_mainItem_Entry{
                            level,
                            target {
                                url,
                                text
                                element {
                                    status
                                }
                            }
                        }
                    },
                }
            },
            ...on navigation_mainItem_Entry{
                target {
                    url,
                    text
                    element {
                        status
                    }
                }
            }
        }

        entry(section: "navigation", orderBy: "dateUpdated desc") {
            dateUpdated @formatDateTime(format: "U")
        }

        globalSet(handle: "organisationInfo") {
            ...on organisationInfo_GlobalSet {
                lockup {
                    url
                }
            }
        }
    }
`

export const NAVIGATION_LAST_UPDATED = `
    query {
        entry(section: "navigation", orderBy: "dateUpdated desc", status: null) {
            dateUpdated @formatDateTime(format: "U")
        }
    }
`
