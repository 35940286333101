// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';

import {
    faArrowToBottom as farArrowToBottom,
    faCheck as farCheck,
    faChevronDown as farChevronDown,
    faChevronUp as farChevronUp,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faDollarSign as farDollarSign,
    faEnvelope as farEnvelope,
    faHandshake as farHandshake,
    faLink as farLink,
    faLockAlt as farLockAlt,
    faPlus as farPlus,
    faPoundSign as farPoundSign,
    faSearch as farSearch,
    faSortAlphaDown as farSortAlphaDown,
    faSortAlphaDownAlt as farSortAlphaDownAlt,
    faSortAmountDown as farSortAmountDown,
    faSortAmountDownAlt as farSortAmountDownAlt,
    faSortSizeDown as farSortSizeDown,
    faSortSizeDownAlt as farSortSizeDownAlt,
    faSquare as farSquare,
    faTimesCircle as farTimesCircle,
    faList as farList,
    
} from '@fortawesome/pro-regular-svg-icons';

import {
    faBars as fasBars,
    faCalendarDay as fasCalendarDay,
    faCheckSquare as fasCheckSquare,
    faCircle as fasCircle,
    faChevronCircleDown as fasChevronCircleDown,
    faChevronCircleLeft as fasChevronCircleLeft,
    faChevronCircleRight as fasChevronCircleRight,
    faCloudDownloadAlt as fasCloudDownloadAlt,
    faDownload as fasDownload,
    faExternalLinkAlt as fasExternalLinkAlt,
    faGlobeEurope as fasGlobeEurope,
    faHashtag as fasHashtag,
    faMapMarkerAlt as fasMapMarkerAlt,
    faPhone as fasPhone,
    faPrint as fasPrint,
    faSort as fasSort,
    faSquareFull as fasSquareFull,
    faInfoCircle as fasInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faFacebookF as fabFacebookF,
    faGithub as fabGithub,
    faInstagram as fabInstagram,
    faLinkedinIn as fabLinkedinIn,
    faTwitter as fabTwitter,
    faVimeoV as fabVimeoV,
    faWikipediaW as fabWikipediaW,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
    faAngleDown as falAngleDown,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faAtom as falAtom,
    faBooks as falBooks,
    faBookReader as falBookReader,
    faBullseyeArrow as falBullseyeArrow,
    faCalculator as falCalculator,
    faChalkboardTeacher as falChalkboardTeacher,
    faCheck as falCheck,
    faChevronUp as falChevronUp,
    faClock as falClock,
    faCompass as falCompass,
    faEllipsisH as falEllipsisH,
    faEllipsisV as falEllipsisV,
    faExternalLinkSquare as falExternalLinkSquare,
    faFileArchive as falFileArchive,
    faFileExcel as falFileExcel,
    faFilePdf as falFilePdf,
    faFilePowerpoint as falFilePowerPoint,
    faFileWord as falFileWord,
    faFilesMedical as falFilesMedical,
    faGlobe as falGlobe,
    faGlobeEurope as falGlobeEurope,
    faHourglassStart as falHourglassStart,
    faIdCard as falIdCard,
    faLaptop as falLaptop,
    faAddressCard as falAddressCard,
    faLightbulbOn as falLightbulbOn,
    faMicroscope as falMicroscope,
    faNewspaper as falNewspaper,
    faPalette as falPalette,
    faPhoneLaptop as falPhoneLaptop,
    faPlus as falPlus,
    faSchool as falSchool,
    faStopwatch as falStopwatch,
    faTimes as falTimes,
    faTrophy as falTrophy,
    faTrees as falTrees,
    faUser as falUser,
    faUsers as falUsers,
    faUserFriends as falUserFriends,
} from '@fortawesome/pro-light-svg-icons';

// import {
//     faFilePdf as fatFilePdf,
//     faFileWord as fatFileWord,
// } from '@fortawesome/pro-thin-svg-icons'

// load font-awesome libraries
library.add(
    fabFacebookF,
    fabGithub,
    fabInstagram,
    fabLinkedinIn,
    fabTwitter,
    fabVimeoV,
    fabWikipediaW,
    fabYoutube,
    falAngleDown,
    falAngleLeft,
    falAngleRight,
    falAddressCard,
    falAtom,
    falBookReader,
    falBooks,
    falBullseyeArrow,
    falCalculator,
    falChalkboardTeacher,
    falCheck,
    falChevronUp,
    falClock,
    falCompass,
    falEllipsisH,
    falEllipsisV,
    falExternalLinkSquare,
    falFileArchive,
    falFileExcel,
    falFilePdf,
    falFilePowerPoint,
    falFileWord,
    falFilesMedical,
    falGlobe,
    falGlobeEurope,
    falHourglassStart,
    falIdCard,
    falLaptop,
    falLightbulbOn,
    falMicroscope,
    falNewspaper,
    falPalette,
    falPhoneLaptop,
    falPlus,
    falSchool,
    falStopwatch,
    falTimes,
    falTimes,
    falTrees,
    falTrophy,
    falUser,
    falUserFriends,
    falUsers,
    farArrowToBottom,
    farCheck,
    farChevronDown,
    farChevronLeft,
    farChevronRight,
    farChevronUp,
    farDollarSign,
    farEnvelope,
    farHandshake,
    farLink,
    farList,
    farLockAlt,
    farPlus,
    farPoundSign,
    farSearch,
    farSortAlphaDown,
    farSortAlphaDownAlt,
    farSortAmountDown,
    farSortAmountDownAlt,
    farSortSizeDown,
    farSortSizeDownAlt,
    farSquare,
    farTimesCircle,
    fasBars,
    fasCalendarDay,
    fasCheckSquare,
    fasChevronCircleDown,
    fasChevronCircleLeft,
    fasChevronCircleRight,
    fasCircle,
    fasCloudDownloadAlt,
    fasDownload,
    fasExternalLinkAlt,
    fasGlobeEurope,
    fasHashtag,
    fasMapMarkerAlt,
    fasInfoCircle,
    fasPhone,
    fasPrint,
    fasSort,
    fasSquareFull
);

// convert i tags to SVG
dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});
