import * as uiActions from './uiActions.js';
import * as uiGetters from './uiGetters.js';
import * as uiMutations from './uiMutations.js';

export const UI = {
    namespaced: true,
    state: () => ({
        searchSettings: {
            expanded: false,
            errorPage: null,
        },
        token: null,
        crsf: null,
        showSubscription: true,
        placeholders: null,
        loading: true,
    }),
    actions: uiActions,
    getters: uiGetters,
    mutations: uiMutations,
}
