<template>
    <div class="container hidden h-28 max-w-screen-xl mx-auto md:flex items-center bg-white">
        <div class="flex items-center h-full">
            <!-- Logo -->
            <div class="w-16 h-16 bg-gray-blue-200 animate-pulse" />

            <div class="flex items-center h-16 ml-16 space-x-12">
                <span
                    v-for="i in 5"
                    :key="i"
                    class="relative inline-flex items-center h-full"
                >
                    <span class="block w-16 h-1 bg-gray-blue-200 animate-pulse" />
                </span>
            </div>
        </div>
    </div>
</template>