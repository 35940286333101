export const GET_CSRF = (state) => {
    return state.csrf
}

export const GET_TOKEN = (state) => {
    return state.token
}

export const GET_ERRORS = (state) => {
    return state.errors;
}

export const GET_SUCCESS = (state) => {
    return state.success;
}

export const GET_FORM = (state) => {
    return state.form;
}