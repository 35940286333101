export const SET_CSRF = (state, data) => {
    state.csrf = data;
}

export const SET_TOKEN = (state, data) => {
    state.token = data;
}

export const SET_FORM = (state, data) => {
    state.form = data;
}

export const SET_ERRORS = (state, data) => {
    state.errors = data;
}

export const SET_SUCCESS = (state, data) => {
    state.success = data;
}