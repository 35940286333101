export const setGqlToken = (state, data) => {
    state.gqlToken = data;
}

export const setCsrf = (state, data) => {
    state.csrf = data;
}

export const setNews = (state, data) => {
    state.news = data;
}
