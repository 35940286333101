export const PLACEHOLDERS_QUERY =
    `
    query {
        globalSet(handle: "placeholders") {
            ...on placeholders_GlobalSet {
                teaserPlaceholder {
                    ...on teasers_Asset {
                        optimizeTeasers {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
            }
        }
    }
`
