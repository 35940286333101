export const SET_CSRF = (state, data) => {
    state.csrf = data;
}

export const SET_TOKEN = (state, data) => {
    state.token = data;
}

export const SET_NAV_ITEMS = (state, data) => {
    state.items = data;
}

export const SET_LAST_UPDATED = (state, data) => {
    state.lastUpdated = data;
}

export const SET_BRANDING = (state, data) => {
    state.branding.lockup = data;
}

export const SET_EVENT = (state, data) => {
    state.event = data;
}